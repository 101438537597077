
import { defineComponent, ref } from "vue";
import { getFromVueEnv } from "@/helpers/misc";
import AHgroup from "@/components/atoms/a-hgroup.vue";
import ADivider from "@/components/atoms/a-divider.vue";
import MPanel from "@/components/molecules/m-panel.vue";
import { REGISTRATION_PATHS, ROUTE_PATHS } from "@/router/config";
import { getRegistration, RegistrationResponse } from "@/services/user.service";
import GroupIcon from "@/assets/user-group.svg";
import UserIcon from "@/assets/user.svg";
import { _RouteLocationBase } from "vue-router";
import { useI18n } from "vue-i18n";

interface ManageTab {
  key: string;
  icon: string;
  name: string;
  route?: Partial<_RouteLocationBase>;
}

export default defineComponent({
  components: {
    AHgroup,
    ADivider,
    MPanel,
  },
  methods: {
    navigationToFaq() {
      const link = getFromVueEnv("faq_link");
      if (!link) {
        return;
      }
      window.open(link, "_blank");
    },
    async setRegistrationData() {
      const response = await getRegistration();
      this.tabs = this.setTabs(response);
    },
    hasUser() {
      const foundUser = this.tabs.filter(
        (tab) => tab.route?.params?.type === "individual"
      );
      return foundUser.length > 0;
    },
    setTabs(data: RegistrationResponse[]) {
      return data.map((tab) => {
        const isSingle = tab.workFlow === "individual";
        const key = isSingle ? tab.user.id : tab?.organisation?.id ?? "";
        const name = isSingle ? tab.user.name : tab?.organisation?.name;
        return {
          key: key,
          icon: isSingle ? UserIcon : GroupIcon,
          name: name ?? "",
          route: {
            name: "manage",
            params: {
              type: tab.workFlow,
              id: tab._id,
            },
          },
        };
      });
    },
  },
  mounted() {
    this.setRegistrationData();
  },
  setup() {
    const tabs = ref<ManageTab[]>([]);
    const { t, locale } = useI18n();
    return {
      tabs,
      links: {
        group: `/${locale.value}/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.GROUP}`,
        single: `/${locale.value}/${ROUTE_PATHS.REGISTRATION}/${REGISTRATION_PATHS.SINGLE}`,
      },
    };
  },
});
