import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "grid"
}
const _hoisted_2 = { class: "grid mt-4" }
const _hoisted_3 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_hgroup = _resolveComponent("a-hgroup")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_m_panel = _resolveComponent("m-panel")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_hgroup, {
      heading: _ctx.$t('overview.heading')
    }, null, 8, ["heading"]),
    _createVNode(_component_a_divider, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("overview.divider")), 1)
      ]),
      _: 1
    }),
    (_ctx.tabs.length)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createVNode(_component_m_panel, { tabs: _ctx.tabs }, null, 8, ["tabs"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "vp-button w-full font-bold m-2",
        label: _ctx.$t('cta.register.club'),
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.links.group)))
      }, null, 8, ["label"]),
      (!_ctx.hasUser())
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "vp-button w-full font-bold m-2 w-full p-button-link p-button-text",
            label: _ctx.$t('cta.register.volunteer'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push(_ctx.links.single)))
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_a_hgroup, {
        variant: "",
        heading: _ctx.$t('faq.heading'),
        subheading: _ctx.$t('faq.subheading')
      }, null, 8, ["heading", "subheading"]),
      _createVNode(_component_Button, {
        class: "vp-button p-0 vp-font-small p-button-link font-bold",
        icon: "pi pi-chevron-right vp-font-small",
        iconPos: "right",
        type: "button",
        onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.navigationToFaq()), ["prevent"])),
        label: _ctx.$t('cta.faq')
      }, null, 8, ["label"])
    ])
  ], 64))
}